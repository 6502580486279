<template lang="pug">
  .paid-module-page(v-if="module" offset-overflow)
    v-container(fluid grid-list-md).pa-0
      v-layout.lesslight.my-0(row wrap)
        v-flex.module__header_block.pa-0(xs12)
          img.image--blur(
            :src="module.inactive_image || placeholder"
            @error="replaceByDefault"
          )
          v-container.py-0
            img.image(
              :src="module.inactive_image || placeholder"
              @error="replaceByDefault"
            )
            .module__header
              h1.mb-0 {{ module.module_name }}

    v-container(grid-list-lg)
      .module-content
        p.module-subtitle {{ module.description }}
        .module__content_line(v-if="module.description")
        p.module-content__text(v-html="getContent")
        .module__content_line(v-if="module.inactive_video")
        .module-content__video.mt-4.mx-auto(v-if="module.inactive_video" ref="moduleYoutubeDiv")
          youtube(
            ref="moduleYoutube"
            :video-id="module.inactive_video | urlToIdYoutube"
            fitParent
            resize
            @ready="ready"
          )

    .module-actions
      v-container(grid-list-lg)
        v-btn.ml-0(
          :to="{ name: 'modules' }"
          depressed
        ) Назад
        v-btn.ml-0(
          @click="nextModule(0)"
          depressed color="primary"
        )
          v-icon.font-weight-regular.mr-1 arrow_back
          | Предыдущий модуль
        v-btn.ml-0(
          @click="nextModule(1)"
          depressed color="primary"
        ) Следующий модуль
          v-icon.font-weight-regular.ml-1 arrow_forward

  the-loading(v-else)

</template>

<script>
import { getIdFromUrl } from 'vue-youtube';

import directory from '@/services/directory';
import TheLoading from '@/components/TheLoading';

const placeholder = require('@/assets/images/placeholder.svg');

export default {
  name: 'PaidModulePage',
  components: { TheLoading },
  filters: {
    urlToIdYoutube(val) {
      return getIdFromUrl(val);
    },
  },
  data() {
    return {
      modules: [],
      moduleName: null,
      module: null,
      modulesPagination: {
        page: 1,
        getMore: true,
      },
      placeholder,
    };
  },
  computed: {
    getContent() {
      if (!this.module.content_operators) return '';
      if (!this.module.content_operators.inactive) return '';
      if (!this.module.content_operators.inactive.content) return '';
      return this.module.content_operators.inactive.content;
    },
    youtube() {
      if (!this.$refs.moduleYoutube) return false;
      return this.$refs.moduleYoutube.player;
    },
  },
  created() {
    this.nullPlayer();
    this.moduleName = this.$route.params.module;
    this.getModuleInfo();
    this.getModulesForNavigator();
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    /**
     * Получение информации о модуле
     */
    getModuleInfo() {
      const params = {
        'search[module]': this.moduleName,
        'expand': 'inactive_video,inactive_image,content_operators',
      };
      this.$rHandler(
        directory.getModulesInfo(params),
        ({ data }) => {
          [this.module] = data.data;
          setTimeout(() => (this.playVideoOnVisible()));
        },
      );
    },
    /**
     * Заменить изображение на placeholder при ошибке загрузки изображения модуля
     */
    replaceByDefault(e) {
      e.target.src = placeholder;
    },
    /**
     * Запускает видео в момент попадания в поле экрана.
     * И останавливает когда в поле экрана не попадает
     */
    playVideoOnVisible() {
      if (this.$refs.moduleYoutubeDiv) {
        const elem = this.$refs.moduleYoutubeDiv;
        const observer = new IntersectionObserver((entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) this.playVideo();
          else this.pauseVideo();
        });

        observer.observe(elem);
      }
    },
    playVideo() {
      if (this.youtube) this.youtube.playVideo();
    },
    pauseVideo() {
      if (this.youtube) this.youtube.pauseVideo();
    },
    /**
     * Получаем список модулей для кнопки след/пред
     */
    getModulesForNavigator() {
      const params = {
        sort: '-id',
        fields: 'module',
        page: this.modulesPagination.page,
      };
      return this.$rHandler(
        directory.getModulesInfo(params),
        ({ data, headers }) => {
          this.modules.push(...data.data);
          const currentPage = Number(headers['x-pagination-current-page']);
          const pageCount = Number(headers['x-pagination-page-count']);
          this.modulesPagination.page = currentPage + 1;
          this.modulesPagination.getMore = pageCount > currentPage;
          return data;
        },
      );
    },
    /**
     * Переход на другой модуль
     * @param dir {Number}: 1 - след, 2 - пред.
     * @returns {Promise<boolean>}
     */
    async nextModule(dir) {
      const items = this.modules;
      const currentIndex = items.findIndex((item) => item.module === this.moduleName);
      // если не найден индекс загружаем след. страницу и ищем там индекс
      // случается когда модуль на 2й странице, а полученный список только 1 страница.
      if (currentIndex < 0) {
        await this.getModulesForNavigator();
        this.nextModule(dir);
        return false;
      }
      const nextIndex = dir ? currentIndex + 1 : currentIndex - 1;

      let module = '';
      if (nextIndex < 0) {
        module = items[items.length - 1];
      } else if (items[nextIndex]) {
        module = items[nextIndex];
      } else if (this.modulesPagination.getMore) {
        await this.getModulesForNavigator();
        module = items[nextIndex];
      } else {
        [module] = items;
      }

      this.nullPlayer();
      this.$router.push({ name: 'module-page', params: { ...module } });
      return true;
    },
    nullPlayer() {
      if (this.$refs.moduleYoutube) { this.$refs.moduleYoutube.player = null; }
    },
  },
};
</script>

<style lang="scss" scoped>

  .layout {
    position: relative;
  }

  .module__header_block {
    height: 500px;
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;

    @media (max-width: 960px) {
      height: 400px;
    }
    @media (max-width: 679px) {
      height: 300px;
    }

    .image--blur {
      filter: blur(7px) brightness(.5);
      transform: scale(1.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      background-color: white;
      @media (max-width: 679px) {
        display: none;
      }
    }

    .container {
      height: 100%;
      position: relative;

      .image {
        filter: brightness(.6);
        height: 100%;
        object-fit: contain;
        object-position: center;
        margin: 0 auto;
        background-color: white;
        display: block;
      }

      @media (max-width: 679px) {
        padding: 0;
        .image {
          object-fit: cover;
          width: 100%;
        }
        .module__header {
          padding: 0 10px;
        }
      }

      .module__header {
        position: absolute;
        bottom: 20px;

        h1 {
          color: #F8F8F8;
          margin-bottom: 20px;
          line-height: 1.3;

          @media (min-width: 960px) {
            font-size: 40px;
          }
        }
      }
    }
  }

  .module-content {
    .module-subtitle {
      font-weight: bold;
    }

    ::v-deep p {
      text-align: justify;
      font-size: 16px;
      margin-bottom: 0;
    }

    .module__content_line {
      width: 25%;
      margin: 15px 0;
      height: 2px;
      background-color: var(--link-color);
      opacity: .75;
    }

    &__video {
      position: relative;
      width: 80%;
      background-color: $lesslight-color;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .module-actions {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: $light-color;
    z-index: 2;
    margin-top: 30px;
    border-top: 2px solid $primary-color;
    // box-shadow: 0 -2px 5px rgba(0,0,0, .1);
  }

  .theme--dark .module-content__text ::v-deep * {
    color: $dark-color !important;
  }

</style>
